
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { Options, Vue } from "vue-class-component";
import CycleV2 from "../cycle-component/index-v2.vue";
import DetailProcess from "../detail-process-component/index.vue";
import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import {
  DashboardData,
  PosFollowUpDashboard
} from "@/domain/entities/Dashboard";
import {
  getDataLocalStorage,
  storeDatatoLocalStorage
} from "@/app/infrastructures/misc/Utils";
import convertPhoneNumber from "@/app/infrastructures/misc/common-library/ConvertPhoneNumber";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatPriceWithoutCurrency from "@/app/infrastructures/misc/common-library/FormatPriceWithoutCurrency";
import { GTMDashboardEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/dashboard-events";

@Options({
  components: {
    CycleV2,
    DetailProcess
  }
})
export default class Outgoing extends Vue {
  get activeMenu() {
    return DashboardController.activeMenuTabs.child;
  }
  get tabs() {
    return [
      {
        value: "outgoing",
        title: "Belum Diproses"
      },
      {
        value: "incoming",
        title: "Sudah Diproses"
      }
    ];
  }
  fetchData() {
    DashboardController.getCutOffConsoleOutgoingStt();
  }
  fetchPosFollowUpData() {
    DashboardController.getListPosFollowUpDashboard();
  }
  get loading() {
    return DashboardController.isLoadingCutOff;
  }
  get isLoadingPosFollowUp() {
    return DashboardController.isLoadingPosFollowUp;
  }
  get error() {
    return DashboardController.errorCause;
  }
  cutOffOutgoingSttGroup(type: string): any {
    const data = groupBy(
      DashboardController.cutOffConsoleOutgoingSttData?.filter(
        item => item.type === type
      ),
      item => item.section
    );
    return isEmpty(data) ? undefined : data;
  }
  cutOffFilterBySection(section: string, type: string) {
    return this.cutOffOutgoingSttGroup(type)
      ? [...this.cutOffOutgoingSttGroup(type)[section]]
      : [];
  }
  get onProcessCycleData() {
    const cycle1 = this.cutOffFilterBySection("cycle1", this.activeMenu);
    const cycle2 = this.cutOffFilterBySection("cycle2", this.activeMenu);

    return [
      {
        ...cycle1[0],
        title: cycle1[0]?.title,
        sttData: cycle1?.slice(1)
      },
      {
        ...cycle2[0],
        title: cycle2[0]?.title,
        sttData: cycle2?.slice(1)
      }
    ];
  }
  get detailProcessData() {
    const onTime = this.cutOffFilterBySection("onTime", this.activeMenu);
    const late = this.cutOffFilterBySection("late", this.activeMenu);
    const invalid = this.cutOffFilterBySection("invalid", this.activeMenu);
    const backlog = this.cutOffFilterBySection("backlog", this.activeMenu);
    const backlogToday = this.cutOffFilterBySection(
      "backlogToday",
      this.activeMenu
    );
    const hanging = this.cutOffFilterBySection("hanging", this.activeMenu);

    return [
      {
        ...onTime[0],
        title: onTime[0]?.title,
        iconColor: "text-red-lp-100",
        borderColor: "border-green-lp-600",
        sttData: onTime?.slice(1)
      },
      {
        ...late[0],
        title: late[0]?.title,
        iconColor: "text-red-lp-100",
        borderColor: "border-pink-lp-300",
        sttData: late?.slice(1)
      },
      {
        ...invalid[0],
        title: invalid[0]?.title,
        iconColor: "text-red-lp-100",
        borderColor: "border-pink-lp-300",
        sttData: invalid?.slice(1)
      },
      {
        ...backlog[0],
        title: backlog[0]?.title,
        backgroundColor: "bg-aqua-lp-200",
        sttData: backlog?.slice(1)
      },
      {
        ...backlogToday[0],
        title: backlogToday[0]?.title,
        backgroundColor: "bg-aqua-lp-200",
        sttData: backlogToday?.slice(1)
      },
      {
        ...hanging[0],
        title: hanging[0]?.title,
        backgroundColor: "bg-purple-lp-100",
        sttData: hanging?.slice(1)
      }
    ];
  }

  get errorCause() {
    return DashboardController.errorCause;
  }
  fetchPosList() {
    DashboardController.getListPosFollowUpDashboard();
  }
  get pagination() {
    return DashboardController.posFollowUpData?.pagination;
  }
  get dataList() {
    return DashboardController.posFollowUpData?.data;
  }
  get columns() {
    return [
      {
        name: "No",
        styleHead: "w-12 text-white text-left whitespace-no-wrap align-top",
        render: (_item: PosFollowUpDashboard, index: number) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${(this
            .pagination.page -
            1) *
            this.pagination.limit +
            index +
            1}</div>
          </div>`;
        }
      },
      {
        name: "Nama POS",
        styleHead: "w-56 text-white text-left whitespace-no-wrap align-top",
        render: (item: PosFollowUpDashboard) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${item?.posName}</div>
          </div>`;
        }
      },
      {
        name: "POS Code",
        styleHead: "w-32 text-white text-left whitespace-no-wrap align-top",
        render: (item: PosFollowUpDashboard) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${item?.posCode}</div>
          </div>`;
        }
      },
      {
        name: "No Telp POS",
        styleHead: "w-44 text-white text-left whitespace-no-wrap align-top",
        key: "actionable_column",
        showButton: (item: PosFollowUpDashboard) => !!item.phoneNumber,
        styleCustom: "justify-between items-center",
        icon: "whatsapp",
        vtooltip: (item: PosFollowUpDashboard) => {
          const phoneNumberPos = this.isDisableWhatsapp(item)
            ? `Aktif kembali pada <span class='font-semibold'>${formatDate(
                this.getDataWaSession(item.posCode)?.end
              )}</span> dan muat ulang halaman`
            : "Klik Chat di Whatsapp. <span class='font-semibold'>Anda hanya dapat menghubungi POS kembali setelah 1 jam berikutnya!</span>";
          return `<span class='text-12px text-black-lp-300'>${phoneNumberPos}</span>`;
        },
        isUnderline: true,
        disableButtonValue: this.isDisableWhatsapp,
        onClickValue: this.goToWhatsapp,
        render: (item: PosFollowUpDashboard) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px break-all">${item?.phoneNumber ||
            "-"}</div>
          </div>`;
        }
      },
      {
        name: "Belum Diproses",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        columnKey: "on_process",
        sortingKey: "total_on_process",
        icon: this.iconSorting("total_on_process"),
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        vtooltipHeader: (column: any) => this.sortingDescription(column),
        vtooltip: () =>
          "<span class='text-12px text-black-lp-300'>Klik ke Detail</span>",
        isUnderline: true,
        disableButtonValue: (item: PosFollowUpDashboard) => !item?.unprocessed,
        onClickValue: this.goToDetail,
        render: (item: PosFollowUpDashboard) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-green-lp-400 text-14px">${this.formatPriceWithoutCurrency(
            item?.unprocessed
          )}</div>
          </div>`;
        }
      },
      {
        name: "Backlog Hari Ini",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        columnKey: "backlog1",
        sortingKey: "total_backlog1",
        icon: this.iconSorting("total_backlog1"),
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        vtooltipHeader: (column: any) => {
          this.sortingDescription(column);
        },
        vtooltip: () =>
          "<span class='text-12px text-black-lp-300'>Klik ke Detail</span>",
        isUnderline: true,
        disableButtonValue: (item: PosFollowUpDashboard) => !item?.backlogToday,
        onClickValue: this.goToDetail,
        render: (item: PosFollowUpDashboard) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-green-lp-400 text-14px">${this.formatPriceWithoutCurrency(
            item?.backlogToday
          )}</div>
          </div>`;
        }
      },
      {
        name: "Backlog 2-7 Hari",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        columnKey: "backlog",
        sortingKey: "total_backlog",
        icon: this.iconSorting("total_backlog"),
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        vtooltipHeader: (column: any) => this.sortingDescription(column),
        vtooltip: () =>
          "<span class='text-12px text-black-lp-300'>Klik ke Detail</span>",
        isUnderline: true,
        disableButtonValue: (item: PosFollowUpDashboard) => !item?.backlog,
        onClickValue: this.goToDetail,
        render: (item: PosFollowUpDashboard) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-green-lp-400 text-14px">${this.formatPriceWithoutCurrency(
            item?.backlog
          )}</div>
          </div>`;
        }
      },
      {
        name: "Hanging",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        columnKey: "hanging",
        sortingKey: "total_hanging",
        icon: this.iconSorting("total_hanging"),
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        vtooltipHeader: (column: any) => this.sortingDescription(column),
        vtooltip: () =>
          "<span class='text-12px text-black-lp-300'>Klik ke Detail</span>",
        isUnderline: true,
        disableButtonValue: (item: PosFollowUpDashboard) => !item?.hanging,
        onClickValue: this.goToDetail,
        render: (item: PosFollowUpDashboard) => {
          return `<div class="overflow-ellipsis text-left space-y-1">
          <div class="overflow-ellipsis text-left text-green-lp-400 text-14px">${this.formatPriceWithoutCurrency(
            item?.hanging
          )}</div>
          </div>`;
        }
      }
    ];
  }
  get sorting() {
    return DashboardController.sorting;
  }
  get isDescending() {
    return this.sorting?.sortBy === "desc";
  }
  sortingDescription(column: any) {
    return `<span class='text-12px text-black-lp-300'>${
      this.isDescending && this.sorting?.orderBy === column?.sortingKey
        ? "Urutkan dari yang terbesar ke terkecil"
        : "Urutkan dari yang terkecil ke terbesar"
    }</span>`;
  }
  iconSorting(key: string) {
    return this.disableIconHeader(key) ||
      (this.isDescending && this.sorting?.orderBy === key)
      ? "descending"
      : "ascending";
  }
  disableIconHeader(key: string) {
    return this.sorting?.orderBy !== key;
  }
  onSorting(column: any) {
    DashboardController.setSorting({
      sortBy:
        this.isDescending && this.sorting?.orderBy === column?.sortingKey
          ? "asc"
          : "desc",
      orderBy: column?.sortingKey
    });
    this.fetchPosFollowUpData();
  }
  isDisableWhatsapp(item: PosFollowUpDashboard) {
    return !!this.getDataWaSession(item.posCode)?.disabled;
  }
  goToWhatsapp(item: PosFollowUpDashboard) {
    const phoneFormat = convertPhoneNumber(item.phoneNumber);
    GTMDashboardEvent(
      "fu_pos_wa",
      {
        posCode: item.posCode,
        noTelp: phoneFormat
      },
      ["timestamp"]
    );

    window.open(
      `https://api.whatsapp.com/send?phone=${phoneFormat}&text=Halo%20Agen%20${
        item.posName
      }%0A%0AKami%20ingin%20menginformasikan%20STT%20yang%20perlu%20Anda%20proses%20secepatnya%2C%20diantaranya%3A%0A${formatPriceWithoutCurrency(
        item.unprocessed
      )}%20STT%20Belum%20diproses%0A${formatPriceWithoutCurrency(
        item.backlogToday
      )}%20STT%20Backlog%20hari%20ini%0A${formatPriceWithoutCurrency(
        item.backlog
      )}%20STT%20Backlog%202-7%20hari%0A${formatPriceWithoutCurrency(
        item.hanging
      )}%20STT%20Hanging%0A%0AHarap%20langsung%20memproses%20STT%20agar%20paket%20dapat%20diproses%20lebih%20cepat%20%26%20sampai%20sesuai%20estimasi%20pengiriman%0A%0ATerima%20kasih`,
      "_blank"
    );

    this.waSessionData.push({
      start: new Date().getTime(),
      end: new Date(
        new Date().setHours(
          new Date().getHours() + 1,
          new Date().getMinutes(),
          0
        )
      ).getTime(),
      posCode: item.posCode
    });

    storeDatatoLocalStorage(`WaSession`, this.waSessionData);
  }

  waSessionData: any = [];
  getDataWaSession(code: string) {
    const data = this.waSessionData;
    if (data?.length && code) {
      const detailData = data?.find((item: any) => item.posCode === code);

      return {
        ...detailData,
        end: detailData?.end || "",
        disabled: this.onCheckDateAvailable(
          new Date().getTime(),
          new Date(detailData?.end).getTime()
        )
      };
    }
    return "";
  }

  getDataLocalStorage() {
    const data: any[] = JSON.parse(getDataLocalStorage(`WaSession`));

    if (data) {
      const result = data?.filter(item =>
        this.onCheckDateAvailable(
          new Date().getTime(),
          new Date(item?.end).getTime()
        )
      );
      storeDatatoLocalStorage(`WaSession`, result);
      this.waSessionData = result;
    }
  }

  onCheckDateAvailable(start: number, end: number) {
    return start < end;
  }

  openTimestamp = 0;
  activeMenuCache = "";
  mounted() {
    this.getDataLocalStorage();
    this.activeMenuCache = this.activeMenu;
    this.openTimestamp = new Date().getTime();
  }
  beforeUnmount() {
    GTMDashboardEvent(`session_view_${this.activeMenuCache}`, {
      openTimestamp: this.openTimestamp,
      closeTimestamp: new Date().getTime()
    });
  }

  goToDetail(item: PosFollowUpDashboard, _: number, columnKey: string) {
    let title = "";
    let status = "";
    switch (columnKey) {
      case "on_process":
        title = "Belum Diproses";
        status = "On Process";
        GTMDashboardEvent(
          "pos_belum_diproses",
          {
            posCode: item.posCode,
            noTelp: convertPhoneNumber(item.phoneNumber)
          },
          ["timestamp"]
        );
        break;

      case "backlog1":
        title = "Backlog Hari Ini";
        status = "Backlog 1 Day";
        GTMDashboardEvent(
          "pos_backlog1",
          {
            posCode: item.posCode,
            noTelp: convertPhoneNumber(item.phoneNumber)
          },
          ["timestamp"]
        );
        break;

      case "backlog":
        title = "Backlog 2-7 Hari";
        status = "Backlog";
        GTMDashboardEvent(
          "pos_backlog2",
          {
            posCode: item.posCode,
            noTelp: convertPhoneNumber(item.phoneNumber)
          },
          ["timestamp"]
        );
        break;

      case "hanging":
        title = "Hanging";
        status = "Hanging";
        GTMDashboardEvent(
          "pos_hanging",
          {
            posCode: item.posCode,
            noTelp: convertPhoneNumber(item.phoneNumber)
          },
          ["timestamp"]
        );
        break;

      default:
        break;
    }

    const data = new DashboardData({
      title,
      detailTitle: item.posName,
      posCode: item.posCode,
      status,
      endpoint: "/pos/cut_off"
    });
    DashboardController.onToDetail(data);
  }
  formatPriceWithoutCurrency(value: number) {
    return formatPriceWithoutCurrency(value);
  }
}
