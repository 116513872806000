
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DashboardConsoleRequest } from "@/data/payload/api/DashboardRequests";
import { DashboardData } from "@/domain/entities/Dashboard";
import groupBy from "lodash/groupBy";
import { Vue, Options } from "vue-class-component";
import { dashboardConsoleData } from "../../module/card-data";
import LayoutCard from "./layout-card.vue";

@Options({
  components: {
    LayoutCard
  }
})
export default class DashboardConsole extends Vue {
  mounted() {
    DashboardController.setDashboardConsoleData();
    this.getDashboardConsoleData();
  }

  get activeMenu() {
    return DashboardController.activeMenuTabs.main;
  }
  getData(time: number, item: DashboardData) {
    const { name } = this.$route;

    return new Promise(
      resolve =>
        name === "dashboard" &&
        this.activeMenu === "all-stt" &&
        setTimeout(() => {
          resolve(
            DashboardController.getDashboardConsoleData(
              new DashboardConsoleRequest({
                cache: true,
                type: item.type,
                lastStatus: item.status
              })
            )
          );
        }, time)
    );
  }

  async getDashboardConsoleData() {
    const data = dashboardConsoleData.filter(item => item.status);
    for await (const [index, item] of data.entries()) {
      await this.getData(index ? 500 : 0, item);
    }
  }
  get dashboardConsoleData() {
    return groupBy(DashboardController.dashboardConsoleData, item => item.type);
  }
  countTotalSttByGroup(data: DashboardData[]) {
    data[0]["totalStt"] = data.reduce(
      (a, item: any, idx) => (idx ? a + item["totalStt"] : 0),
      0
    );
    data[0]["loading"] = data.findIndex(item => item.loading === false) < 0;
  }
  get incomingReceivedConsoleData(): DashboardData[] {
    const data = [...this.dashboardConsoleData["incoming-received"]];
    this.countTotalSttByGroup(data);
    return data;
  }
  get incomingNotFinishedConsoleData(): DashboardData[] {
    const data = [...this.dashboardConsoleData["incoming-not-finished"]];
    this.countTotalSttByGroup(data);
    return data;
  }
  get transitUndeliveredConsoleData(): DashboardData[] {
    const data = [...this.dashboardConsoleData["transit_undelivered"]];
    this.countTotalSttByGroup(data);
    return data;
  }
  get transitUnfinishedConsoleData(): DashboardData[] {
    const data = [...this.dashboardConsoleData["transit_unfinished"]];
    this.countTotalSttByGroup(data);
    return data;
  }
  get outgoingReceivedConsoleData(): DashboardData[] {
    const data = [...this.dashboardConsoleData["outgoing-received"]];
    this.countTotalSttByGroup(data);
    return data;
  }
  get outgoingNotFinishedConsoleData(): DashboardData[] {
    const data = [...this.dashboardConsoleData["outgoing-not-finished"]];
    this.countTotalSttByGroup(data);
    return data;
  }
  // load more
  isLoadMore = false;
  onLoadMore() {
    this.isLoadMore = !this.isLoadMore;
  }
}
