
import { prop, Vue } from "vue-class-component";
import {
  formatDecimal,
  formatPriceWithoutCurrency
} from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { DashboardConsoleRequest } from "@/data/payload/api/DashboardRequests";
import { DashboardData } from "@/domain/entities/Dashboard";

class Props {
  data = prop<Array<any>>({
    default: [],
    type: Array
  });
  active = prop<boolean>({
    default: false,
    type: Boolean
  });
  backgroundColor = prop<string>({
    default: "",
    type: String
  });
  iconColor = prop<string>({
    default: "",
    type: String
  });
}
export default class LayoutCard extends Vue.with(Props) {
  sttData(isFirst: boolean) {
    if (isFirst) return this.data[0];
    else return this.data.slice(1);
  }
  fetchConsoleData(data: DashboardData) {
    DashboardController.getDashboardConsoleData(
      new DashboardConsoleRequest({
        type: data?.type,
        lastStatus: data?.status,
        attempt: 2
      })
    );
  }
  formatDecimal(value: number) {
    return formatDecimal(formatPriceWithoutCurrency(value));
  }
  toDetail(cardData: any, tooltip: any) {
    tooltip?.hide();
    DashboardController.onToDetail(cardData);
  }
  toDownload(cardData: any, tooltip: any) {
    tooltip?.hide();
    DashboardController.onDownloadXlsx(cardData);
  }
  get styles() {
    return {
      backgroundColor: this.backgroundColor.includes("bg")
        ? this.backgroundColor
        : `bg-${this.backgroundColor}`,
      iconColor: this.iconColor.includes("text")
        ? this.iconColor
        : `text-${this.iconColor}`
    };
  }
}
