
import { GTMDashboardEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/dashboard-events";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { prop, Vue } from "vue-class-component";

class Props {
  data = prop<any>({
    default: {},
    type: Object
  });
  disabled = prop<boolean>({
    default: false,
    type: Boolean
  });
}
export default class DashboardOptions extends Vue.with(Props) {
  toDetail(cardData: any, tooltip: any) {
    GTMDashboardEvent(cardData?.gtmEventName, undefined, ["timestamp"]);
    tooltip?.hide();
    DashboardController.onToDetail(cardData);
  }
  toDownload(cardData: any, tooltip: any) {
    tooltip?.hide();
    DashboardController.onDownloadXlsx(cardData);
  }
}
