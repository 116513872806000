
import { Options, Vue } from "vue-class-component";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import AllStt from "./all-stt/index.vue";
import CurrentStt from "./current-stt/index.vue";
import CodDashboard from "@/app/ui/views/dashboard/cod-dashboard/index.vue";
import { FlagsDashboardCod } from "@/feature-flags/dashboard/cod";

@Options({
  components: { AllStt, CurrentStt, CodDashboard }
})
export default class DashboardTabs extends Vue {
  get activeMenu() {
    return DashboardController.activeMenuTabs.main;
  }
  onChangeConsoleTabs(value: string) {
    DashboardController.setActiveMenuTabs({ main: value });
  }
  created() {
    DashboardController.setActiveMenuTabs({
      main: "current-stt",
      child: "outgoing"
    });
  }
  get tabs() {
    const tempTabs = [
      {
        value: "all-stt",
        title: "Seluruh STT"
      },
      {
        value: "current-stt",
        title: "STT Saat Ini"
      },
    ];

    if (FlagsDashboardCod.permission_show_tab_cod_dashboard.isEnabled()) {
      tempTabs.push({
        value: "cod-dashboard",
        title: "COD"
      });
    }

    return tempTabs;
  }
}
