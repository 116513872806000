import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "overflow-auto flex-grow space-y-6 text-black-lp-300",
  style: {"height":"calc(100vh - 242px)","padding-bottom":"165px"}
}
const _hoisted_2 = { class: "flex flex-col lg:flex-row space-y-4 lg:space-y-0 space-x-0 lg:space-x-4 py-5 pr-6 border rounded-lg" }
const _hoisted_3 = {
  key: 0,
  class: "rounded-lg border border-gray-lp-400 pt-5 px-5"
}
const _hoisted_4 = { class: "w-full relative pb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_CycleV2 = _resolveComponent("CycleV2")!
  const _component_DetailProcess = _resolveComponent("DetailProcess")!
  const _component_Title = _resolveComponent("Title")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_ctx.error)
    ? (_openBlock(), _createBlock(_component_ErrorMessage, {
        key: 0,
        errorType: _ctx.error,
        errorImage: "system-error-console-current-stt",
        imageWidth: "72",
        errorTitle: "Halaman dalam perbaikan",
        errorMessage: "Maaf halaman ini sedang dalam perbaikan, Kami akan segera online kembali.",
        onTryAgain: _ctx.fetchData,
        class: "h-screen"
      }, null, 8, ["errorType", "onTryAgain"]))
    : (_openBlock(), _createBlock("div", _hoisted_1, [
        _createVNode("div", _hoisted_2, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.onProcessCycleData, (data, idx) => {
            return (_openBlock(), _createBlock(_Fragment, { key: idx }, [
              (_ctx.activeMenu === 'outgoing')
                ? (_openBlock(), _createBlock(_component_CycleV2, {
                    key: 0,
                    loading: _ctx.loading,
                    data: data
                  }, null, 8, ["loading", "data"]))
                : _createCommentVNode("", true),
              (_ctx.activeMenu === 'incoming')
                ? (_openBlock(), _createBlock(_component_CycleV2, {
                    key: 1,
                    loading: _ctx.loading,
                    data: data
                  }, null, 8, ["loading", "data"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _createVNode(_component_DetailProcess, {
          loading: _ctx.loading,
          title: 
        `Informasi ${_ctx.activeMenu === 'outgoing' ? 'Outgoing' : 'Incoming'}`
      ,
          data: _ctx.detailProcessData
        }, null, 8, ["loading", "title", "data"]),
        (_ctx.activeMenu === 'outgoing')
          ? (_openBlock(), _createBlock("div", _hoisted_3, [
              _createVNode("div", _hoisted_4, [
                _createVNode(_component_Title, { label: "Daftar POS yang Belum Mengirim STT" }),
                _createVNode(_component_TableV2, {
                  columns: _ctx.columns,
                  onRequest: _ctx.fetchPosList,
                  data: _ctx.dataList,
                  loading: _ctx.isLoadingPosFollowUp,
                  errorCause: _ctx.errorCause,
                  paginationStyle: "v3",
                  pagination: _ctx.pagination,
                  "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
                  pinnedSubstractHeight: "290px",
                  borderTop: false,
                  borderBottom: false,
                  groupAction: "",
                  iconColor: "green-lp-100"
                }, null, 8, ["columns", "onRequest", "data", "loading", "errorCause", "pagination"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]))
}