
import { formatPriceWithoutCurrency } from "@/app/infrastructures/misc/Utils";
import { Options, prop, Vue } from "vue-class-component";
import Card from "../../card/index.vue";

class Props {
  data = prop<object>({
    default: null,
    type: Object
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean
  });
  isShowTotal = prop<boolean>({
    default: true,
    type: Boolean
  });
  backgroundColor = prop<string>({
    default: "",
    type: String
  });
}
@Options({ components: { Card } })
export default class IndexV2 extends Vue.with(Props) {
  formatPriceWithoutCurrency(value: number) {
    return formatPriceWithoutCurrency(value);
  }
}
