
import { GTMDashboardEvent } from "@/app/infrastructures/misc/gtm-event/custom-events/dashboard-events";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { Vue, prop, Options } from "vue-class-component";
import Card from "../../card/index.vue";

class Props {
  defaultOpened = prop<boolean>({
    type: Boolean,
    default: true
  });
  loading = prop<boolean>({
    default: false,
    type: Boolean
  });
  data = prop<Array<any>>({
    default: [],
    type: Array
  });
  title = prop<string>({
    type: String,
    default: ""
  });
  bgHead = prop<string>({
    type: String,
    default: "bg-aqua-lp-200"
  });
  borderHead = prop<string>({
    type: String,
    default: "border-aqua-lp-100"
  });
}

@Options({ components: { Card } })
export default class DetailProcess extends Vue.with(Props) {
  mounted() {
    this.model = this.defaultOpened;
  }
  get activeMenu() {
    return DashboardController.activeMenuTabs.child;
  }
  model = false;
  get modelAccordion() {
    return this.model;
  }

  get expandIcon() {
    return this.modelAccordion ? "chevron-up-new" : "chevron-down-new";
  }
  toggleAccordion() {
    this.model = !this.model;
  }

  cutOffData: any = [];

  findTotal(status: string, key: string) {
    this.cutOffData = [...this.data];
    let totalStt = 0;

    if (this.data.length) {
      this.data.map(item => {
        if (item.section === status) {
          if (item.sttData.length) {
            item.sttData.map((stt: any) => {
              if (stt.key === key) {
                totalStt = stt.totalStt;
              }
            });
          } else {
            totalStt = item.totalStt;
          }
        }
      });
    }
    return totalStt;
  }

  get isOutgoing() {
    return this.activeMenu === "outgoing";
  }

  get dataList() {
    const row1Key = this.isOutgoing ? "bkdSti" : "stiDestDel";
    const row2Key = this.isOutgoing ? "stiCargoPlane" : "delPod";

    const data = [
      {
        label: this.isOutgoing ? "di STI" : "di DEL",
        key: row1Key,
        onTime: this.findTotal("onTime", row1Key),
        late: this.findTotal("late", row1Key),
        invalid: this.findTotal("invalid", "total"),
        backlogToday: this.findTotal("backlogToday", row1Key),
        backlog: this.findTotal("backlog", row1Key),
        hanging: this.findTotal("hanging", row1Key)
      },
      {
        label: this.isOutgoing ? "di Kargo Pesawat" : "di POD",
        key: row2Key,
        onTime: this.findTotal("onTime", row2Key),
        late: this.findTotal("late", row2Key),
        invalid: this.isOutgoing ? this.findTotal("invalid", "total") : 0, // temporary invalid use this row, after revert will move to first data
        backlogToday: this.findTotal("backlogToday", row2Key),
        backlog: this.findTotal("backlog", row2Key),
        hanging: this.findTotal("hanging", row2Key)
      },
      {
        label: "di Kargo Truk/Kereta",
        key: "stiCargoTruck",
        onTime: this.findTotal("onTime", "stiCargoTruck"),
        late: this.findTotal("late", "stiCargoTruck"),
        invalid: 0,
        backlogToday: this.findTotal("backlogToday", "stiCargoTruck"),
        backlog: this.findTotal("backlog", "stiCargoTruck"),
        hanging: this.findTotal("hanging", "stiCargoTruck")
      }
    ];

    // temporary use this condition
    if (this.isOutgoing) {
      data.shift();
    }
    if (!this.isOutgoing) {
      data.pop();
    }
    return data;
  }
  get columns() {
    return [
      {
        name: "Ontime",
        columnKey: "onTime",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        vtooltipHeader: () =>
          `<div class="text-12px text-left px-3 text-black-lp-300" style="margin-top: -16px;">
          Daftar STT yang sudah diupdate dan masih di dalam waktu cut off
          </div>`,
        key: "actionable_column",
        styleCustom: "items-end",
        showButton: () => true,
        disableButtonValue: (item: any) => !item.onTime,
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left space-y-1" style="margin-top: -8px; margin-bottom: -4px;">
          <div class="overflow-ellipsis text-left text-20px font-bold">${item?.onTime}</div>
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${item?.label}</div>
          </div>`;
        }
      },
      {
        name: "Late",
        columnKey: "late",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        vtooltipHeader: () =>
          `<div class="text-12px text-left px-3 text-black-lp-300" style="margin-top: -16px;">
          Daftar STT yang sudah diupdate di luar waktu cut off
          </div>`,
        key: "actionable_column",
        styleCustom: "items-end",
        showButton: () => true,
        disableButtonValue: (item: any) => !item.late,
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left space-y-1" style="margin-top: -8px; margin-bottom: -4px;">
          <div class="overflow-ellipsis text-left text-20px font-bold">${item?.late}</div>
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${item?.label}</div>
          </div>`;
        }
      },
      {
        name: "Invalid",
        columnKey: "invalid",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        vtooltipHeader: () =>
          `<div class="text-12px text-left px-3 text-black-lp-300" style="margin-top: -16px;">
          Daftar STT yang sudah di proses namun melewati status yang seharusnya 
          </div>`,
        key: "actionable_column",
        styleCustom: "items-end",
        showButton: (_: any, index: number) => index === 0,
        disableButtonValue: (item: any) => !item.invalid,
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left space-y-1" style="margin-top: -8px; margin-bottom: -4px;">
          <div class="overflow-ellipsis text-left text-20px font-bold">${item?.invalid}</div>
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">Invalid</div>
          </div>`;
        }
      },
      {
        name: "Backlog Hari Ini",
        columnKey: "backlogToday",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        vtooltipHeader: () =>
          `<div class="text-12px text-left px-3 text-black-lp-300" style="margin-top: -16px;">
          Daftar STT yang belum diproses hari ini dan sudah diluar waktu cut off 
          </div>`,
        key: "actionable_column",
        styleCustom: "items-end",
        showButton: () => true,
        disableButtonValue: (item: any) => !item.backlogToday,
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left space-y-1" style="margin-top: -8px; margin-bottom: -4px;">
          <div class="overflow-ellipsis text-left text-20px font-bold">${item?.backlogToday}</div>
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${item?.label}</div>
          </div>`;
        }
      },
      {
        name: "Backlog 2-7 Hari",
        columnKey: "backlog",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        vtooltipHeader: () =>
          `<div class="text-12px text-left px-3 text-black-lp-300" style="margin-top: -16px;">
          Daftar STT yang belum diproses selama 2-7 hari dan sudah diluar waktu cut off 
          </div>`,
        key: "actionable_column",
        styleCustom: "items-end",
        showButton: () => true,
        disableButtonValue: (item: any) => !item.backlog,
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left space-y-1" style="margin-top: -8px; margin-bottom: -4px;">
          <div class="overflow-ellipsis text-left text-20px font-bold">${item?.backlog}</div>
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${item?.label}</div>
          </div>`;
        }
      },
      {
        name: "Hanging",
        columnKey: "hanging",
        styleHead: "w-40 text-white text-left whitespace-no-wrap align-top",
        vtooltipHeader: () =>
          `<div class="text-12px text-left px-3 text-black-lp-300" style="margin-top: -16px;">
          Daftar STT yang belum diupdate dan sudah lewat dari 7 hari 
          </div>`,
        key: "actionable_column",
        styleCustom: "items-end",
        showButton: () => true,
        disableButtonValue: (item: any) => !item.hanging,
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-left space-y-1" style="margin-top: -8px; margin-bottom: -4px;">
          <div class="overflow-ellipsis text-left text-20px font-bold">${item?.hanging}</div>
          <div class="overflow-ellipsis text-left text-gray-lp-800 text-14px">${item?.label}</div>
          </div>`;
        }
      }
    ];
  }

  actionGroupOptions = (item: any, columnKey: number) => {
    let data: any = null;

    this.cutOffData.map((e: any) => {
      if (e?.section === columnKey) {
        if (e?.sttData.length) {
          e?.sttData.map((stt: any) => {
            if (stt.key === item?.key) {
              data = stt;
            }
          });
        } else {
          data = e;
        }
      }
    });

    return [
      {
        label: "View Detail",
        iconV2: true,
        icon: "file-chart",
        clickFunction: () => {
          GTMDashboardEvent(data?.gtmEventName, undefined, ["timestamp"]);
          DashboardController.onToDetail(data);
        }
      },
      {
        label: "Download .xlsx",
        iconV2: true,
        icon: "file-download",
        clickFunction: () => DashboardController.onDownloadXlsx(data)
      }
    ];
  };
  get isLoading() {
    return DashboardController.isLoadingCutOff;
  }
  get errorCause() {
    return DashboardController.errorCause;
  }
}
