import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IncomingOutgoing = _resolveComponent("IncomingOutgoing")!
  const _component_Tabs = _resolveComponent("Tabs")!

  return (_openBlock(), _createBlock(_component_Tabs, {
    modelValue: _ctx.activeMenu,
    onChange: _ctx.onChangeConsoleTabs,
    data: _ctx.tabs,
    rounded: false,
    contained: "",
    marginBottom: 5,
    gap: 4
  }, {
    default: _withCtx(() => [
      _createVNode(_component_IncomingOutgoing, { key: _ctx.activeMenu })
    ]),
    _: 1
  }, 8, ["modelValue", "onChange", "data"]))
}