
import { Options, prop, Vue } from "vue-class-component";
import {
  countdownTimer,
  formatDate,
  formatPriceWithoutCurrency
} from "@/app/infrastructures/misc/Utils";
import DashboardOptions from "./dashboard-options.vue";

class Props {
  loading = prop<boolean>({
    default: false,
    type: Boolean
  });
  title = prop<string>({
    default: "",
    type: String
  });
  icon = prop<string>({
    default: "",
    type: String
  });
  startDate = prop<string>({
    default: "",
    type: String
  });
  endDate = prop<string>({
    default: "",
    type: String
  });
  backgroundColor = prop<string>({
    default: "",
    type: String
  });
  border = prop<string>({
    default: "",
    type: String
  });
  borderColor = prop<string>({
    default: "border-gray-lp-400",
    type: String
  });
  backgroundColorIcon = prop<string>({
    default: "",
    type: String
  });
  iconColor = prop<string>({
    default: "white",
    type: String
  });
  rounded = prop<string>({
    default: "",
    type: String
  });
  styleTimer = prop<string>({
    default: "",
    type: String
  });
  totalStt = prop<number>({
    default: 0,
    type: Number
  });
  data = prop<any>({
    default: {},
    type: Object
  });
  isCountdown = prop<boolean>({
    default: false
  });
}
@Options({
  components: { DashboardOptions },
  watch: {
    data() {
      if (this.startDate && this.endDate) {
        countdownTimer({
          startDate: this.startDate,
          endDate: this.endDate,
          format: "hh : mm",
          callback: this.setCountdownTimer
        });
      }
    }
  }
})
export default class CardConsole extends Vue.with(Props) {
  timer = "";
  setCountdownTimer(value: string) {
    this.timer = value;
  }

  mounted() {
    if (this.startDate && this.endDate) {
      if (this.isCountdown) {
        countdownTimer({
          startDate: this.startDate,
          endDate: this.endDate,
          format: "hh : mm",
          callback: this.setCountdownTimer
        });
      } else {
        this.setTimezone();
      }
    }
  }

  get time() {
    const getTime = this.timer.replace(/[\D]/g, "");
    return {
      hours: Number(getTime?.slice(0, getTime.length - 2)),
      minutes: Number(getTime?.slice(-2))
    };
  }
  get stylesTimer() {
    let style = "";
    if (this.time.hours === 0 && this.time.minutes === 0) {
      style = "bg-gray-lp-400 text-black-lp-300";
    } else if (this.time.hours < 1) {
      style = "bg-red-lp-1100 text-red-lp-1000";
    } else if (this.time.hours < 2) {
      style = "bg-yellow-lp-400 text-yellow-lp-800";
    } else {
      style = "bg-green-lp-500 text-green-lp-400";
    }
    return `${this.styleTimer} ${style}`;
  }

  formatPriceWithoutCurrency(value: number) {
    return formatPriceWithoutCurrency(value);
  }
  maxTime = "";
  setTimezone() {
    this.maxTime = "";
    if (
      new Date() > new Date(this.startDate) &&
      new Date() <= new Date(this.endDate) &&
      !this.endDate?.match(/0001-01-01T00:00:00/)
    ) {
      this.maxTime = formatDate(this.endDate, "HH:mm");
    }
  }
}
