
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { Options, Vue } from "vue-class-component";
import IncomingOutgoing from "./incoming-outgoing/index.vue";

@Options({ components: { IncomingOutgoing } })
export default class CurrentStt extends Vue {
  get activeMenu() {
    return DashboardController.activeMenuTabs.child;
  }
  onChangeConsoleTabs(value: string) {
    DashboardController.setActiveMenuTabs({ child: value });
  }
  get tabs() {
    return [
      {
        value: "outgoing",
        title: "Outgoing"
      },
      {
        value: "incoming",
        title: "Incoming"
      }
    ];
  }
  mounted() {
    DashboardController.setActiveMenuTabs({
      child: "outgoing"
    });
    this.fetchData();
  }
  fetchData() {
    DashboardController.initTable();
    DashboardController.getCutOffConsoleOutgoingStt();
    DashboardController.getListPosFollowUpDashboard();
  }
}
